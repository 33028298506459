import React, { useState, useEffect } from 'react';
import generateSpeech from '../components/generateSpeech';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';

function FeedbackDisplay({ feedback }) {
  const feedbackPoints = feedback.split(/\d+\.\s/);
  const [audioPlayed, setAudioPlayed] = useState(false);

  return (
    <div className="feedback-display">
      {feedbackPoints.map((point, index) => {
        if (!point.trim()) return null; // Don't render an empty point

        // If it's the first point, format it without the index number
        const formattedPoint = (index === 0) ? point : `${index}. ${point}`;

        // Render each point as a separate paragraph
        return <p key={index}>{formattedPoint}</p>;
      })}
    </div>
  );
}



const StoryFeedback = () => {
  const [story, setStory] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const playFeedback = async (feedbackText) => {
    if (!audioPlayed) {
      try {
        const audioUrl = await generateSpeech(feedbackText);
        const audio = new Audio(audioUrl);
        await audio.play();
        setAudioPlayed(true);
      } catch (error) {
        console.error("Error generating speech:", error);
      }
    }
  };

  const handleStoryChange = (event) => {
    setStory(event.target.value);
  };

  const handleAnalyseClick = () => {
    setLoading(true);
    setFeedback(null);
    setAudioPlayed(false); // Reset audio played state
    axios.post(`${API_BASE_URL}/analyse_story`, { story: story })
      .then(response => {
        setFeedback(response.data.feedback);
        playFeedback(response.data.feedback); // Call playFeedback here
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div className="story-feedback game-summary questionMaths">
    <div className="story-input">
        <textarea
          className="story-textarea"
          value={story}
          onChange={handleStoryChange}
          rows={10}
          cols={50}
          placeholder="Enter your story here..."
        />
        <button onClick={handleAnalyseClick} disabled={loading}>
          Fyn's Feedback
        </button>
      </div>
      {loading && <div>Loading...</div>}
      {feedback && <FeedbackDisplay feedback={feedback} />}
    </div>
  );
};

export default StoryFeedback;