import React, { useState, useEffect } from 'react';
import '../css/React.css';
import Menu from './Menu';
import { useAuth0 } from '@auth0/auth0-react';
import introPage from '../assets/backgrounds/IntroPage.jpg';

function Welcome() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001';
  localStorage.setItem('API_BASE_URL', API_BASE_URL);

  const { isAuthenticated, user } = useAuth0();
  const [localUser, setLocalUser] = useState(null);

  useEffect(() => {
    // Log authentication status
    // console.log(isAuthenticated ? 'Authenticated' : 'Not Authenticated');

    if (isAuthenticated && user) {
      // Set user in state and localStorage if authenticated
      setLocalUser(user.sub);
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('user', user.sub);
    } else {
      // Clear user and localStorage when not authenticated
      setLocalUser(null);
      localStorage.removeItem('authenticated');
      localStorage.removeItem('user');
    }
  }, [isAuthenticated, user]);



  const [version, setVersion] = useState('');

  useEffect(() => {
    fetch('/Version.txt')
      .then((response) => response.text())
      .then((data) => setVersion(data))
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="welcome-page">
      <Menu />
      {/* <iframe
        id="godot-iframe"
        src="/godot/intro/index.html"
        style={{ width: '800px', height: '800px' }}
        title="Godot Game"
      ></iframe> */}
       <p> <strong>Welcome to Fynschool</strong>—where learning leaps off the page and into a world of wonder.</p>
       <p>Guided by Fyn the Fish, your curious, quirky mentor, you’ll explore enchanted islands filled with <strong>maths puzzles, storytelling secrets, reasoning riddles, and more</strong>.</p>
      {/* <p>
        Welcome to Fynschool, a realm where learning dances with delightful surprises! Here, every lesson is an adventure through the enchanting worlds of maths, creative writing, comprehension and more—all designed to guide you to the school of your dreams.
      </p><p>Specialising in the wondrous journeys of 7+, 8+, and 11+ exams, our experience is as magical as it is fun. */}
        <a href="/godot/intro/index.html">
          <img src={introPage} alt="Intro Page" style={{ width: '60%', height: 'auto' }} />
        </a>
      <p>From creative writing to comprehension, from dictation to interview tricks—every challenge is part of the adventure.</p>
      <p> Whether you're preparing for the <strong>7+, 8+, or 11+</strong>, this isn't just exam prep... it's a journey through a world built to spark imagination, unlock confidence, and make learning unforgettable.</p>
        <a href="/godot/intro/index.html"> Let's go to the Island!</a>

        <p> Sign in to unlock more islands and discover even more extraordinary features!</p>


      <p className="version">{version}</p>
    </div>

  );
}


export default Welcome;
